import { Routes, mapToCanActivate } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { signInRoutes } from './core/routes/signin-routes';
import { freeTrialReasonRoutes, requestFreeTrialRoutes } from './core/routes/request-free-trial-routes';
import { userSettingsRoutes } from './core/routes/user-settings-routes';
import { dashboardRoutes } from './core/routes/dashboard-routes';
import { buyPackageRoutes } from './core/routes/buy-package';

export const routes: Routes = [
    signInRoutes,
    freeTrialReasonRoutes,
    requestFreeTrialRoutes,
    userSettingsRoutes,
    buyPackageRoutes,
    {
        path: 'pages/create-a-parent-account',
        loadComponent: () =>
            import('./features/auth/register/register-parent/register-parent.component').then(
                (m) => m.RegisterParentComponent
            ),
    },
    {
        path: 'pages/register-student',
        loadComponent: () =>
            import('./features/auth/register/register-student/register-student.component').then(
                (m) => m.RegisterStudentComponent
            ),
    },
    dashboardRoutes,
    // { path: "**", redirectTo: "/request-free-trial", pathMatch: "full" },
    //Wild Card Route for 404 request 
    {
        path: '', redirectTo: "/request-free-trial", pathMatch: "full"
    },
    {
        path: '**', pathMatch: 'full',
        component: PageNotFoundComponent
    },
];
