import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, inject, DestroyRef, signal, computed, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { IStopImpersonateStudentRequest } from '@GeneratedTsFiles/index';
import { MenuItem } from 'primeng/api';
import { ScrollPanel, ScrollPanelModule } from 'primeng/scrollpanel';
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { untilDestroyed } from 'src/app/core/helpers/until-destroyed';
import { ApiService } from 'src/app/core/services/api.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DataApiStateService } from 'src/app/core/services/data-api-state.service';
import { EventBusService, EmitEvent, Events } from 'src/app/core/services/event-bus.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { TopMenuItem, IUserRole } from '../../models/general.model';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { CommonModule, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { StyleClassModule } from 'primeng/styleclass';
import { NotificationItemCardComponent } from '../notifications/notification-item-card/notification-item-card.component';
import { FormFieldValidationMessageComponent } from '../prime/form-field-validation-message/form-field-validation-message.component';
import { SidebarMainMenuListItemComponent } from '../sidebar/sidebar-main-menu-list-item/sidebar-main-menu-list-item.component';
import { SidebarTopbarMenuComponent } from './sidebar-topbar-menu/sidebar-topbar-menu.component';
import { TopbarMenuItemsComponent, TopbarMenuType } from './topbar-menu-items/topbar-menu-items.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TopbarSidebarComponent } from './topbar-sidebar/topbar-sidebar.component';

@Component({
  selector: 'app-topbar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    MenuModule,
    StyleClassModule,
    SidebarModule,
    ButtonModule,
    AvatarModule,
    BadgeModule,
    NgIf,
    SidebarMainMenuListItemComponent,
    ScrollPanelModule,
    SplitButtonModule,
    FormFieldValidationMessageComponent,
    SidebarTopbarMenuComponent,
    NotificationItemCardComponent,
    TopbarMenuItemsComponent,
    TopbarSidebarComponent,
  ],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss',
})
export class TopbarComponent implements OnInit {
  @ViewChild('buyPackageMenu', { static: false }) buyPackageMenu: ElementRef | undefined;
  @ViewChild('scrollPanelNotifChild', { static: false }) scrollPanelNotifChild: ScrollPanel | undefined;
  @ViewChild('sidebarRef') sidebarRef!: Sidebar;
  @Output() hideSidebar = new EventEmitter<boolean>();

  private readonly destroy: DestroyRef = inject(DestroyRef);

  readonly topBarMenuType = TopbarMenuType;

  authService = inject(AuthService);
  generalService = inject(GeneralService);
  apiService = inject(ApiService);
  private eventBusService = inject(EventBusService);
  private readonly dataStateService = inject(DataApiStateService);
  private readonly router = inject(Router);

  topMenuItems: TopMenuItem[] = [];
  searchValue = '';
  items: MenuItem[] | undefined;
  buyPackageItemsVisible = signal(false);
  buyPackageLinks: MenuItem[] = [];
  private untilDestroyed = untilDestroyed();
  private injector = inject(Injector);

  #userToSignal = this.authService.userDecodedJWTData$;
  user = computed(() => {
    return this.#userToSignal();
  });
  isAuthenticatedComputed = computed(() => this.authService.userAuthenticated$());
  userRole = computed(() => this.authService.userDecodedJWTData$());
  students$ = computed(() => {
    console.log(this.dataStateService.parentStudents.state());
    return this.dataStateService.parentStudents.state() || []
  });

  IUserRole = IUserRole;

  ngOnInit(): void {
    this.initTopbarItems();
    this.initEvents();
    this.prepareBuyPackageLinks();
  }

  toggleBuyPackageItems(): void {
    this.buyPackageItemsVisible.update(v => !v);
  }

  toggleSidebar(): void {
    this.hideSidebar.emit(true);
    this.generalService.sidebarVisible.set(true);
  }

  onSidebarVisibleChange(visible: boolean): void {
    this.generalService.sidebarVisible.set(visible);
  }

  onSelectListItem(event: { item: any, link: string }): void {
    if (event?.link) {
      this.router.navigate([event.link]);
    }
    this.onSidebarVisibleChange(false);
  }

  navigateTo(menuItem: TopMenuItem): void {
    const url = menuItem['link'];
    if (url) {
      this.router.navigate([url]);
      this.generalService.sidebarVisible.set(false);
    }
  }

  logout(): void {
    this.authService.logout();
    this.generalService.sidebarVisible.set(false);
  }

  goToHome(): void {
    this.authService.goToDashboard();
    this.generalService.sidebarVisible.set(false);
  }

  goToNotifications(): void {
    this.authService.goToNotificationsPerRole();
    this.generalService.sidebarVisible.set(false);
  }

  goToUserSettings(): void {
    this.authService.goToUserSettings();
    this.generalService.sidebarVisible.set(false);
  }

  goToFreeTrialForm(): void {
    this.generalService.sidebarVisible.set(false);
    this.router.navigate(['/request-new-trial/free-trial-reason']);
  }

  goToBuyPackage(): void {
    this.generalService.sidebarVisible.set(false);
    this.toggleBuyPackageItems();
    this.router.navigate(['/buy-package/select-new-package']);
  }

  goToBuyPackageExtension(): void {
    this.generalService.sidebarVisible.set(false);
    this.toggleBuyPackageItems();
    this.router.navigate(['/buy-package/select-package-extension']);
  }

  goToCartDetails(): void {
    this.generalService.sidebarVisible.set(false);
    this.generalService.cartSidebarVisible.set(true);
  }

  notifPanelRefresh(): void {
    this.scrollPanelNotifChild?.refresh();
  }

  stopImpersonation(): void {
    this.apiService.stopImpersonateStudent({
      studentRefreshToken: this.authService.getRefreshToken()
    }).subscribe({
      next: (data: IStopImpersonateStudentRequest) => {
        this.authService.handleUserDataAndDecodeJWT(data);
        this.authService.goToDashboardPerRole();
      },
      error: (error) => console.error('Error stopping impersonation:', error)
    });
  }

  private prepareParentStudents(): void {
    const apiCall = this.apiService.getParentStudents({ parentId: this.user()!.id });
    this.dataStateService.handleApiCall(apiCall, this.dataStateService.parentStudents.setState)
      .pipe()
      .subscribe({
        error: (err) => console.error('Error fetching parent students:', err)
      });
  }

  private initEvents(): void {
    // this event is fired when user is logged in
    const userLoggedInSubscription = toObservable(this.user, {
      injector: this.injector
    }).pipe(this.untilDestroyed()).subscribe({
      next: (user) => {
        if (!user) {
          return;
        }
        if (user?.role === IUserRole.PARENT) {
          this.eventBusService.emit(new EmitEvent(Events.StateLoadParentStudents, undefined));

          setTimeout(() => {
            this.eventBusService.emit(new EmitEvent(Events.StateLoadParentStudentsGroups, undefined));
          }, 100);
          // this.prepareParentStudents();
        }
      }
    });


    this.eventBusService.on(Events.ParentImpersonateStopped, () => {
      if (this.user()?.role === IUserRole.PARENT) {
        this.prepareParentStudents();
      }
    });

    this.eventBusService.on(Events.StudentRegistered, () => {
      if (this.user()?.role === IUserRole.PARENT) {
        this.prepareParentStudents();
      }
    });
  }

  private initTopbarItems(): void {
    this.topMenuItems = [
      {
        id: 'languages',
        text: 'Languages',
        position: 'left',
        hidden: false,
        submenu: [
          { id: 'features', text: 'Features' },
          { id: 'solutions', text: 'Solutions' },
          { id: 'customers', text: 'Customers' }
        ]
      },
      {
        id: 'about',
        text: 'About',
        position: 'left',
        submenu: [
          { id: 'aboutCustomers', text: 'Customers' }
        ]
      },
      {
        id: 'packagesMenu',
        text: 'packages',
        position: 'left',
        submenu: []
      },
      {
        id: 'signin',
        text: 'Sign In/Up',
        icon: 'sign-in-icon',
        position: 'right',
        submenu: [
          {
            id: 'teacher',
            text: 'Teacher Hub',
            link: '/signin/teacher',
            icon: 'teacher-signin',
            prefixHtml: 'sign-in-menu-sprite teacher-signin'
          },
          {
            id: 'student',
            text: 'Student Hub',
            link: '/signin/student',
            icon: 'student-signin',
            prefixHtml: 'sign-in-menu-sprite student-signin'
          },
          {
            id: 'parent',
            text: 'Parent Hub',
            link: '/signin/parent',
            icon: 'parent-signin',
            prefixHtml: 'sign-in-menu-sprite parent-signin'
          },
          {
            id: 'create-account',
            text: 'Create an Account',
            link: '/request-free-trial',
            icon: 'create-parent-account',
            prefixHtml: 'sign-in-menu-sprite parcreate-parent-account'
          }
        ]
      },
      {
        id: 'profile',
        text: 'Profile',
        icon: 'profile-icon',
        link: '/dashboard/parent/overview',
        hidden: false,
        submenu: [
          {
            id: 'settings',
            text: 'Settings',
            link: '/user-settings/account-info',
            icon: 'settings-icon',
            hidden: false,
            callback: () => { }
          },
          {
            id: 'logout',
            text: 'Logout',
            icon: 'logout-icon',
            hidden: false,
            callback: () => this.logout()
          }
        ]
      },
      {
        id: 'notifications',
        text: 'Notifications',
        icon: 'notification-icon',
        hidden: true,
      }
    ];
  }

  private prepareBuyPackageLinks(): void {
    this.buyPackageLinks = [
      {
        label: 'Update',
        command: () => { }
      },
      {
        label: 'Delete',
        command: () => { }
      },
      { label: 'Angular Website', url: 'http://angular.io' },
      { separator: true },
      { label: 'Upload', routerLink: ['/fileupload'] }
    ];
  }
}