// src/app/loading-bar.interceptor.ts
import { HttpEvent, HttpInterceptorFn, HttpHandlerFn, HttpRequest, HttpContextToken, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { inject } from '@angular/core';
import { GeneralService } from '../services/general.service';

export const NGX_LOADING_BAR_IGNORED = new HttpContextToken<boolean>(() => false);

export const loadingBarInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
    const loader = inject(GeneralService);
  
    if (req.headers.has('ignoreLoadingBar')) {
      console.warn(
        `Using http headers ('ignoreLoadingBar') to ignore loading bar is deprecated. Use HttpContext instead: 'context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true)'`,
      );
  
      return next(req.clone({ headers: req.headers.delete('ignoreLoadingBar') }));
    }
    if (req.context.get(NGX_LOADING_BAR_IGNORED) === true) {
      return next(req);
    }
  
    let started = false;
    return next(req).pipe(
      tap(() => {
        if (!started) {
          loader.routerLoading.set(true);
          started = true;
        }
      }),
      finalize(() => started && loader.routerLoading.set(false)),
    );
  };