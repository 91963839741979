import { AuthGuard } from '../guards/auth.guard';

export const buyPackageRoutes = {
    path: 'buy-package',
    canActivate: [AuthGuard],
    children: [
        {
            path: '',
            loadComponent: () =>
                import('../../features/buy-package/buy-package.component').then(
                    (m) => m.BuyPackageComponent
                ),
            children: [
                {
                    path: '',
                    loadComponent: () =>
                        import('../../features/buy-package/buy-package-selection/buy-package-selection.component').then(
                            (m) => m.BuyPackageSelectionComponent
                        ),
                    children: [

                        {
                            data: {
                                title: 'Buy a new Lesson Package',
                            },
                            path: 'select-new-package',
                            loadComponent: () =>
                                import('../../features/buy-package/buy-package-selection/buy-package-selection-new-package/buy-package-selection-new-package.component').then(
                                    (m) => m.BuyPackageSelectionNewPackageComponent
                                ),
                        },
                        {
                            data: {
                                title: 'Extend a Package',
                            },
                            path: 'select-package-extension',
                            loadComponent: () =>
                                import('../../features/buy-package/buy-package-extension-selection/buy-package-extension-selection.component').then(
                                    (m) => m.BuyPackageExtensionSelectionComponent
                                ),
                        },
                    ]
                },
            ],
        },
        {
            path: 'checkout',
            loadComponent: () =>
                import('../../features/buy-package/checkout-steps/checkout-steps.component').then(
                    (m) => m.CheckoutStepsComponent
                ),
            children: [
                {
                    data: {
                        onlyInfo: true,
                        showSidebar: false,
                        currentStep: 0,
                        title: 'Cart Details',
                    },
                    path: 'cart-details',
                    loadComponent: () =>
                        import('../../features/buy-package/checkout-steps/checkout-step-cart-details/checkout-step-cart-details.component').then(
                            (m) => m.CheckoutStepCartDetailsComponent
                        ),
                },
                {
                    data: {
                        onlyInfo: false,
                        showSidebar: true,
                        currentStep: 1,
                        title: 'Personal Information',
                        sidebarShowDeleteCartItemsButton: false,
                    },
                    path: 'personal-info',
                    loadComponent: () =>
                        import('../../features/buy-package/checkout-steps/checkout-step-personal-info/checkout-step-personal-info.component').then(
                            (m) => m.CheckoutStepPersonalInfoComponent
                        ),
                },
                {
                    data: {
                        onlyInfo: false,
                        showSidebar: false,
                        sidebarShowDeleteCartItemsButton: false,
                        currentStep: 2,
                        title: 'Payment Completed',
                    },
                    path: 'completed-payment',
                    loadComponent: () =>
                        import('../../features/buy-package/checkout-steps/checkout-step-completed-payment/checkout-step-completed-payment.component').then(
                            (m) => m.CheckoutStepCompletedPaymentComponent
                        ),
                },
            ],
        }
    ],
};