@if (showItemType === menuType.buyPackageDropdown) {
<ng-container *ngFor="let menuItem of topMenuItems">
    <li *ngIf="menuItem.id === 'packagesMenu'" class="relative hidden md:block align-items-center"
        (mouseleave)="onMouseLeave(menuItem.id)">
        @if (authService.isAuth()) {

        @if (userRole()?.role === IUserRole.PARENT) {
        <a pRipple class="inline-flex  align-items-center
    transition-duration-150 
    md:ml-4 mr-2 px-3 cursor-pointer
                buy-package-grad-btn font-medium transition-colors transition-duration-150
    " (mouseenter)="onMouseEnter(menuItem.id)">
            <div class="flex align-items-center justify-content-center gap-2">
                <img src="assets/images/rocket.png" alt="logo" height="24">
                <span class="font-semibold">Buy Package</span>
            </div>
            <i class="pi pi-chevron-down ml-auto lg:ml-3"></i>
        </a>

        <ul #buyPackageMenu *ngIf="menuVisibility[menuItem.id]" class="list-none p-0 m-0 buy-package-grad-btn border-round shadow-0 lg:shadow-2 lg:absolute 
                    surface-overlay  origin-top w-full lg:w-12rem" style="left: 24px;">
            <li class="px-3">
                <a pRipple (click)="goToBuyPackage()"
                    class="flex gap-2 py-1 align-items-center justify-content-start cursor-pointer transition-colors transition-duration-150">
                    <img src="assets/images/icons/meteorite.png" alt="logo" height="18">
                    <span>New Package</span>
                </a>
            </li>
            <li class="px-3">
                <a pRipple (click)="goToBuyPackageExtension()"
                    class="flex gap-2 py-1 align-items-center justify-content-start cursor-pointer transition-colors transition-duration-150">
                    <img src="assets/images/icons/shooting-stars.png" alt="logo" height="18">
                    <span>Buy an extension</span>
                </a>
            </li>
        </ul>
        }
        }
    </li>
</ng-container>
}


@if (!authService.isAuth()) {
<li *ngFor="let menuItem of topMenuItems" class="relative text-900" (mouseleave)="onMouseLeave(menuItem.id)">
    <ng-container *ngIf="menuItem.position === 'right'">
        <a pRipple class="signin-links-start hidden xl:flex px-0 lg:px-3 py-2 align-items-center hover:text-blue-600 font-medium transition-colors 
        transition-duration-150 menu-item" (mouseenter)="onMouseEnter(menuItem.id)">
            <span>{{ menuItem.text }}</span>
            <i class="pi pi-chevron-down text-sm ml-auto lg:ml-2"></i>
        </a>
        <ul *ngIf="menuVisibility[menuItem.id]"
            class="signin-links-list list-none p-0 m-0 border-round shadow-0 lg:shadow-2 lg:absolute surface-overlay origin-top w-full lg:w-13rem">
            <li *ngFor="let submenuItem of menuItem.submenu" [ngClass]="{ 'active': submenuItem.active }">
                <ng-container *ngIf="submenuItem.link; else callbackContent">
                    <a pRipple (click)="navigateTo(submenuItem)"
                        class="flex p-2 align-items-center hover:surface-50 transition-colors transition-duration-150 cursor-pointer">
                        <span class="flex gap-1 justify-content-center">
                            <span *ngIf="submenuItem.prefixHtml" class="{{ submenuItem.prefixHtml }}"></span>
                            {{ submenuItem.text }}
                        </span>
                    </a>
                </ng-container>
                <ng-template #callbackContent>
                    <button pRipple *ngIf="submenuItem.callback" (click)="submenuItem.callback($event)"
                        class="flex p-2 align-items-center hover:surface-50 transition-colors transition-duration-150 cursor-pointer">
                        <span class="flex gap-1 justify-content-center">
                            <span class="sign-in-menu-sprite {{ submenuItem.icon }}"></span>
                            <span [innerHTML]="submenuItem.prefixHtml"></span>
                            {{ submenuItem.text }}
                        </span>
                    </button>
                </ng-template>
            </li>
        </ul>
    </ng-container>
</li>
}

@if (showItemType === menuType.userDropdown) {
<ng-container *ngFor="let menuItem of topMenuItems">
    <li *ngIf="menuItem.id === 'profile'" class="relative hidden xl:block" (mouseleave)="onMouseLeave(menuItem.id)">
        <ng-container>
            <a pRipple class="inline-flex px-0 py-3 align-items-center hover:text-blue-600 font-medium transition-colors 
transition-duration-150 menu-item justify-content-end" (mouseenter)="onMouseEnter(menuItem.id)">
                <div class="avatar-circle">
                    <img src="assets/images/dummy-image.png" class="border-round">
                </div>
                <div class="hidden lg:block lg:ml-2">
                    <div class="text-900 font-medium">Hi {{ user()?.firstName }}</div>
                    <!-- <span class="text-600 font-medium text-sm w-15rem">{{ user()?.firstName }}</span> -->
                </div>
                <i class="pi pi-chevron-down text-sm ml-auto lg:ml-2"></i>
            </a>

            <ul *ngIf="menuVisibility[menuItem.id]" class="list-none p-0 m-0 border-round shadow-0 lg:shadow-2 lg:absolute surface-overlay   
origin-top w-full lg:w-12rem" style="left: -20px;">
                <li *ngFor="let subMenuItem of menuItem.submenu">

                    <ng-container *ngIf="subMenuItem.link; else callbackContent">

                        <a pRipple (click)="navigateTo(subMenuItem)"
                            class="flex no-underline p-3 align-items-center cursor-pointer text-700 hover:surface-50 transition-colors transition-duration-150">
                            <span>{{ subMenuItem.text }}</span>
                        </a>
                    </ng-container>
                    <ng-template #callbackContent>


                        <a pRipple *ngIf="subMenuItem.callback" (click)="subMenuItem.callback($event)"
                            class="flex no-underline p-3 align-items-center text-700 hover:surface-50 transition-colors transition-duration-150">
                            <span>{{ subMenuItem.text }}</span>
                        </a>
                    </ng-template>

                </li>
            </ul>
        </ng-container>
    </li>
</ng-container>
}