import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, inject, Input, Output, type OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { SidebarTopbarMenuComponent } from '../sidebar-topbar-menu/sidebar-topbar-menu.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { GeneralService } from 'src/app/core/services/general.service';

@Component({
    selector: 'app-topbar-sidebar',
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
        SidebarModule,
        SidebarTopbarMenuComponent,
    ],
    templateUrl: './topbar-sidebar.component.html',
    styleUrl: './topbar-sidebar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopbarSidebarComponent implements OnInit {
    readonly generalService = inject(GeneralService);
    readonly authService = inject(AuthService);

    #userToSignal = this.authService.userDecodedJWTData$;
    user = computed(() => {
        return this.#userToSignal();
    });
    isAuthenticatedComputed = computed(() => this.authService.userAuthenticated$());
    userRole = computed(() => this.authService.userDecodedJWTData$());

    @Input() topMenuItems: any[] = [];
    @Output() buyPackageSelected = new EventEmitter<any>();
    ngOnInit(): void { }

    closeCallback(e: any): void {
        this.generalService.sidebarVisible.set(false);
    }
}
