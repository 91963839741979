import { mapToCanActivateChild } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { rolesGuard } from '../guards/roles.guard';
import { teacherResolver } from '../resolvers/teacher.resolver';


export const dashboardRoutes = {
    path: 'dashboard',
    data: {
        showSidebar: true,
    },
    canActivate: [AuthGuard],
    loadComponent: () =>
        import('../../shared/layout/main-layout/main-layout.component').then(
            (m) => m.MainLayoutComponent
        ),
    children: [
        {
            data: {
                showSidebar: false,
                showWaves: false,
                fullWidth: true
            },
            path: 'request-free-trial',
            loadComponent: () =>
                import('../../features/dashboard/parent/parent-profile/request-free-trial-content/request-free-trial-content.component').then(
                    (m) => m.RequestFreeTrialContentComponent
                ),
        },
        {
            path: '',
            loadComponent: () =>
                import('../../features/dashboard/dashboard.component').then(
                    (m) => m.DashboardComponent
                ),
            children: [
                {
                    path: 'parent',
                    loadComponent: () =>
                        import('../../features/dashboard/parent/parent-profile/parent-profile.component').then(
                            (m) => m.ParentProfileComponent
                        ),
                    canActivate: [rolesGuard],
                    data: {
                        role: ['Parent'],
                    },
                    children: [
                        {
                            path: 'overview',
                            loadComponent: () =>
                                import('../../features/dashboard/parent/parent-profile/parent-overview/parent-overview.component').then(
                                    (m) => m.ParentOverviewComponent
                                ),

                            children: [
                            ]
                        },
                        {
                            path: 'calendar',
                            loadComponent: () =>
                                import('../../features/dashboard/parent/parent-profile/parent-overview-calendar/parent-overview-calendar.component').then(
                                    (m) => m.ParentOverviewCalendarComponent
                                ),

                            children: [
                            ]
                        },
                        {
                            path: 'notifications',
                            loadComponent: () =>
                                import('../../features/dashboard/parent/parent-profile/parent-profile-notifications/parent-profile-notifications.component').then(
                                    (m) => m.ParentProfileNotificationsComponent
                                ),

                            children: [
                            ]
                        },
                        {
                            data: {
                                isParentViewingStudent: true,
                            },
                            path: 'student/:studentId',
                            loadComponent: () =>
                                import('../../features/dashboard/parent/parent-profile/parent-student-main-nav/parent-student-main-nav.component').then(
                                    (m) => m.ParentStudentMainNavComponent
                                ),

                            children: [

                                {
                                    data: {
                                        isParentViewingStudent: true,
                                    },
                                    path: 'overview',
                                    loadComponent: () =>
                                        import('../../features/dashboard/parent/parent-profile/parent-student-overview/parent-student-overview.component').then(
                                            (m) => m.ParentStudentOverviewComponent
                                        ),

                                    children: [
                                        {
                                            data: {
                                                isParentViewingStudent: true,
                                            },
                                            path: 'overview',
                                            loadComponent: () =>
                                                import('../../features/dashboard/parent/parent-profile/parent-student-overview/parent-student-overview.component').then(
                                                    (m) => m.ParentStudentOverviewComponent
                                                ),

                                            children: [
                                                {
                                                    path: ':mode',
                                                    loadComponent: () =>
                                                        import('../../features/dashboard/parent/parent-profile/parent-student-overview/parent-student-overview.component').then(
                                                            (m) => m.ParentStudentOverviewComponent
                                                        ),
                                                }
                                            ]
                                        },
                                    ]
                                },

                                {
                                    data: {
                                        isParentViewingStudent: true,
                                    },
                                    path: 'schedule',
                                    loadComponent: () =>
                                        import('../../features/dashboard/inner/inner-schedule-content/inner-schedule-content.component').then(
                                            (m) => m.InnerScheduleContentComponent
                                        ),
                                },

                                {
                                    data: {
                                        isParentViewingStudent: true,
                                    },
                                    path: 'homework',
                                    loadComponent: () =>
                                        import('../../features/dashboard/inner/inner-homework-content/inner-homework-content.component').then(
                                            (m) => m.InnerHomeworkContentComponent
                                        ),
                                },
                                {
                                    data: {
                                        isParentViewingStudent: true,
                                    },
                                    path: 'progress',
                                    loadComponent: () =>
                                        import('../../features/dashboard/inner/inner-progress-content/inner-progress-content.component').then(
                                            (m) => m.InnerProgressContentComponent
                                        ),
                                },
                            ]
                        },
                    ]
                },
                // Teacher Profile routes
                {
                    path: 'teacher',
                    loadComponent: () =>
                        import('../../features/dashboard/teacher/teacher-dashboard-profile/teacher-dashboard-profile.component').then(
                            (m) => m.TeacherDashboardProfileComponent,
                        ),
                    data: {
                        isTeacher: true,
                    },
                    resolve: { data: teacherResolver },
                    children: [
                        {
                            path: 'overview',
                            loadComponent: () =>
                                import('../../features/dashboard/teacher/teacher-dashboard-profile/teacher-overview/teacher-overview.component').then(
                                    (m) => m.TeacherOverviewComponent
                                ),
                            data: {
                                isTeacher: true,
                            },
                            children: [
                            ]
                        },
                        {
                            path: 'schedule',
                            loadComponent: () =>
                                import('../../features/dashboard/teacher/teacher-dashboard-profile/teacher-dashboard-schedule/teacher-dashboard-schedule.component').then(
                                    (m) => m.TeacherDashboardScheduleComponent
                                ),
                            data: {
                                isTeacher: true,
                            },
                            children: [
                            ]
                        },
                        {
                            path: 'library',
                            loadComponent: () =>
                                import('../../features/dashboard/inner/inner-library-content/inner-library-content.component').then(
                                    (m) => m.InnerLibraryContentComponent
                                ),
                            data: {
                                isTeacher: true,
                            },
                        },
                        {

                            data: {
                                isTeacher: true,
                            },
                            path: 'evaluations',
                            loadComponent: () =>
                                import('../../features/dashboard/inner/inner-progress-content/inner-progress-content.component').then(
                                    (m) => m.InnerProgressContentComponent
                                ),
                        },
                        {
                            path: 'trials',
                            loadComponent: () =>
                                import('../../features/dashboard/inner/inner-trial-lessons-content/inner-trial-lessons-content.component').then(
                                    (m) => m.InnerTrialLessonsContentComponent
                                ),
                            data: {
                                isTeacher: true,
                            },
                        },
                        {
                            path: 'evaluation',
                            loadComponent: () =>
                                import('../../features/dashboard/teacher/teacher-student-evaluation/teacher-student-evaluation.component').then(
                                    (m) => m.TeacherStudentEvaluationComponent
                                ),
                            data: {
                                isTeacher: true,
                                showHorizontalTabs: false,
                            },
                        },
                    ]
                },
                // Student Profile routes
                {
                    path: 'student',
                    loadComponent: () =>
                        import('../../features/dashboard/student/student-dashboard-profile/student-dashboard-profile.component').then(
                            (m) => m.StudentDashboardProfileComponent
                        ),
                    data: {
                        showSidebar: true,
                        showWaves: false,
                        isStudent: true,
                    },
                    children: [
                        {
                            data: {
                                showSidebar: true,
                                showWaves: false,
                                isStudent: true,
                            },
                            path: 'overview',
                            loadComponent: () =>
                                import('../../features/dashboard/student/student-dashboard-overview/student-dashboard-overview.component').then(
                                    (m) => m.StudentDashboardOverviewComponent
                                ),

                            children: [
                            ]
                        },
                        {
                            data: {
                                isStudent: true,
                            },
                            path: 'lessons',
                            loadComponent: () =>
                                import('../../features/dashboard/student/student-dashboard-lessons/student-dashboard-lessons.component').then(
                                    (m) => m.StudentDashboardLessonsComponent
                                ),

                            children: [
                            ]
                        },
                        {
                            data: {
                                isStudent: true,
                            },
                            path: 'homework',
                            loadComponent: () =>
                                import('../../features/dashboard/student/student-dashboard-homework/student-dashboard-homework.component').then(
                                    (m) => m.StudentDashboardHomeworkComponent
                                ),
                        },
                        {
                            data: {
                                isStudent: true,
                            },
                            path: 'progress',
                            loadComponent: () =>
                                import('../../features/dashboard/student/student-dashboard-progress-overview/student-dashboard-progress-overview.component').then(
                                    (m) => m.StudentDashboardProgressOverviewComponent
                                ),
                        },
                        {
                            data: {
                                isStudent: true,
                            },
                            path: 'notifications',
                            loadComponent: () =>
                                import('../../features/dashboard/parent/parent-profile/parent-profile-notifications/parent-profile-notifications.component').then(
                                    (m) => m.ParentProfileNotificationsComponent
                                ),

                            children: [
                            ]
                        },
                    ]
                },
            ]
        },
    ]
};