import { CommonModule, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  OnInit,
  Output,
  ViewChild,
  computed,
  inject,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { StyleClassModule } from 'primeng/styleclass';
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { AuthService } from 'src/app/core/services/auth.service';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { GeneralService } from 'src/app/core/services/general.service';
import { IUserRole, TopMenuItem } from '../../models/general.model';
import { SidebarMainMenuListItemComponent } from '../sidebar/sidebar-main-menu-list-item/sidebar-main-menu-list-item.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { IApiResponse, IStopImpersonateStudentRequest, IUserClaims } from '@GeneratedTsFiles/index';
import { ApiService } from 'src/app/core/services/api.service';
import { BadgeModule } from 'primeng/badge';
import { SplitButtonModule } from 'primeng/splitbutton';
import { untilDestroyed } from 'src/app/core/helpers/until-destroyed';
import { EmitEvent, EventBusService, Events } from 'src/app/core/services/event-bus.service';
import { Observable, Subscription } from 'rxjs';
import { DataApiStateService } from '../../../core/services/data-api-state.service';
import { FormFieldValidationMessageComponent } from '../prime/form-field-validation-message/form-field-validation-message.component';
import { SidebarTopbarMenuComponent } from './sidebar-topbar-menu/sidebar-topbar-menu.component';

@Component({
  selector: 'app-topbar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    MenuModule,
    StyleClassModule,
    SidebarModule,
    ButtonModule,
    AvatarModule,
    BadgeModule,
    NgIf,
    SidebarMainMenuListItemComponent,
    ScrollPanelModule,
    SplitButtonModule,
    FormFieldValidationMessageComponent,
    SidebarTopbarMenuComponent,
  ],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss',
})
export class TopbarComponent implements OnInit {
  @ViewChild('buyPackageMenu', { static: false }) buyPackageMenu: ElementRef | undefined;

  @ViewChild('sidebarRef') sidebarRef!: Sidebar;
  topMenuItems: TopMenuItem[] = [];

  closeCallback(e: any): void {
    this.sidebarVisible.set(false);
  }
  @Output() hideSidebar = new EventEmitter<boolean>();
  private readonly destroy: DestroyRef = inject(DestroyRef);
  searchValue: string = '';
  items: MenuItem[] | undefined;
  authService = inject(AuthService);
  generalService = inject(GeneralService);
  apiService = inject(ApiService);
  eventBusService = inject(EventBusService);
  dataStateService = inject(DataApiStateService);
  #user = signal(undefined);
  isMenuVisible = false;
  sidebarVisible = signal(false);
  buyPackageItemsVisible = signal(false);
  buyPackageLinks: MenuItem[] = [];
  private untilDestroyed = untilDestroyed();

  #userToSignal = this.authService.userDecodedJWTData$;
  user = computed(() => {
    return this.#userToSignal();
  });
  isAuthenticatedComputed = computed(() => {
    console.log(this.authService.userAuthenticated$());
    return (this.authService.userDecodedJWTData$());
  });


  userRole = computed(() => {
    return (this.authService.userDecodedJWTData$());
  });

  IUserRole = IUserRole;

  students = this.generalService.dummyStudents;

  students$ = computed(() => {
    return this.dataStateService.parentStudents.state() || [];
  });

  menuVisibility: { [key: string]: boolean } = {
    languages: false,
    about: false,
    profile: false,
    signin: false,
    packagesMenu: false
  };
  private injector = inject(Injector);

  private subscriptions: Subscription[] = [];

  constructor(private router: Router) {
  }


  @HostListener('mouseover')
  onMouseEnter(menuItemId: keyof typeof this.menuVisibility) {
    // console.log(menu);
    // if (!menu) {
    //     return;
    // }

    if (!menuItemId) {
      return;
    }
    this.menuVisibility[menuItemId] = true;
  }

  @HostListener('mouseleave')
  onMouseLeave(menuItemId: keyof typeof this.menuVisibility) {
    console.log(menuItemId);
    if (!menuItemId) {
      return;
    }
    this.menuVisibility[menuItemId] = false;
    this.updateMenuVisibility();
    // console.log('onMouseLeave',this.menuVisibility);
  }


  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const target = event.target as HTMLElement;
    // const menuElement = this.buyPackageMenu!.nativeElement;
    // if (!menuElement.contains(event.target as Node) && !menuElement.classList.contains('hidden')) {
    //     this.toggleBuyPackageItems();
    // }
  }



  ngOnInit(): void {

    this.initEvents();
    this.initTopbarItems();

    // this.isAuthenticated.set(this.authService.isAuth());
    this.items = [
      {
        label: 'Options',
        items: [
          {
            label: '<span class="">Profile</span>',
            escape: false,
            icon: 'pi pi-refresh',
            iconClass: 'text-xl'
          },
          {
            label: '<span class="">Delete</span>',
            escape: false,
            icon: 'pi pi-times',
            iconClass: 'text-xl'
          }
        ]
      },
      {
        label: 'Navigate',
        items: [
          {
            label: 'Angular',
            icon: 'pi pi-external-link',
            url: '#'
          },
          {
            label: 'Router',
            icon: 'pi pi-upload',
            routerLink: '/fileupload'
          }
        ]
      }
    ];

    this.prepareBuyPackageLinks();
    // this.updateMenuVisibility();
  }

  ngOnChanges() {

    // this.updateMenuVisibility();
    console.log('onMouseLeave', this.isAuthenticatedComputed());
    console.log('onMouseLeave', this.user());
    console.log('menuVisibility', this.menuVisibility);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  prepareBuyPackageLinks() {
    this.buyPackageLinks = [
      {
        label: 'Update',
        command: () => {
        }
      },
      {
        label: 'Delete',
        command: () => {
        }
      },
      { label: 'Angular Website', url: 'http://angular.io' },
      { separator: true },
      { label: 'Upload', routerLink: ['/fileupload'] }
    ];
  }

  toggleBuyPackageItems() {
    console.log(this.hasScaleinClass());
    this.isMenuVisible = !this.isMenuVisible;

    // const menuElement = this.buyPackageMenu!.nativeElement;
    // if (menuElement.classList.contains('hidden')) {
    //   menuElement.classList.remove('hidden');
    //   menuElement.classList.add('scalein');
    // } else {
    //   menuElement.classList.remove('scalein');
    //   menuElement.classList.add('fadeout');
    //   setTimeout(() => {
    //     menuElement.classList.add('hidden');
    //     menuElement.classList.remove('fadeout');
    //   }, 150); // Transition duration
    // }

    this.buyPackageItemsVisible.set(!this.buyPackageItemsVisible());
  }

  hasScaleinClass(): boolean {
    return this.buyPackageMenu! ? this.buyPackageMenu!.nativeElement.classList.contains('hidden') : false;
  }

  toggleSidebar() {
    this.hideSidebar.emit(true);
    console.log('true');
    this.sidebarVisible.set(true);
  }


  toggleSubmenu(menu: string): void {
    // this.menuVisibility[menu] = !this.menuVisibility[menu];
  }

  onSidebarVisibleChange($event: any) {
    this.sidebarVisible.set($event);
  }

  onSelectListItem($event: { item: any, link: string }) {
    console.log($event);
    if ($event && $event.link) {
      this.router.navigate([$event.link]);
    }
    this.onSidebarVisibleChange(false);
  }

  navigateTo(menuItem: TopMenuItem | any) {
    const url = menuItem['link'];
    if (!url) {
      return;
    }
    this.router.navigate([url]);
    this.sidebarVisible.set(false);
  }

  logout() {
    this.authService.logout();
    this.sidebarVisible.set(false);
  }

  updateMenuVisibility(): void {
    const isLoggedIn = this.isAuthenticatedComputed(); // Assuming this function checks if the user is logged in
    console.log('updateMenuVisibility', isLoggedIn);
    this.topMenuItems.forEach(item => {
      if (item.id === 'profile' && isLoggedIn) {
        item.hidden = true;
        item.submenu!.forEach(subItem => {
          subItem.hidden = true;
        });
      } else if (item.id === 'signin' && !isLoggedIn) {
        item.hidden = false;
        item.submenu!.forEach(subItem => {
          subItem.hidden = false;
        });
      } else {
        item.hidden = true;
        if (item.submenu) {
          item.submenu!.forEach(subItem => {
            subItem.hidden = true;
          });
        }

      }
    });
  }

  goToLogout() {
    this.authService.logout();
    this.sidebarVisible.set(false);
  }

  goToHome() {
    this.authService.goToDashboard();
    this.sidebarVisible.set(false);
  }

  goToNotifications() {
    this.authService.goToNotificationsPerRole();
    this.sidebarVisible.set(false);
  }

  goToUserSettings() {
    this.authService.goToUserSettings();
    this.sidebarVisible.set(false);
  }

  goToFreeTrialForm() {
    this.sidebarVisible.set(false);
    this.router.navigate(['/request-new-trial/free-trial-reason']);
  }

  goToBuyPackage() {
    this.sidebarVisible.set(false);
    this.toggleBuyPackageItems();
    this.router.navigate(['/buy-package/select-new-package']);
  }

  goToBuyPackageExtension() {
    this.sidebarVisible.set(false);
    this.toggleBuyPackageItems();
    this.router.navigate(['/buy-package/select-package-extension']);
  }

  goToCartDetails() {
    this.sidebarVisible.set(false);
    this.router.navigate(['/buy-package/checkout/cart-details']);
  }

  stopImpersonation() {
    this.apiService.stopImpersonateStudent({
      studentRefreshToken: this.authService.getRefreshToken()
    }).subscribe({
      next: (data: IStopImpersonateStudentRequest) => {
        console.log(data);
        this.authService.handleUserDataAndDecodeJWT(data);
        this.authService.goToDashboardPerRole();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  prepareParentStudents() {

    const apiCall: Observable<any> = this.apiService.getParentStudents({ ParentId: this.user()!.id });
    this.dataStateService.handleApiCall(apiCall, this.dataStateService.parentStudents.setState)
      .pipe(this.untilDestroyed())
      .subscribe({
        next: () => { }, // The state is already handled in handleApiCall
        error: (err) => {
          console.log('error', err);
        } // The state is already handled in handleApiCall
      });

  }

  private initEvents() {

    // this event is fired when user is logged in
    const userLoggedInSubscription = toObservable(this.user, {
      injector: this.injector
    }).pipe(this.untilDestroyed()).subscribe({
      next: (user) => {
        if (!user) {
          return;
        }
        if (user?.role === IUserRole.PARENT) {
          this.eventBusService.emit(new EmitEvent(Events.StateLoadParentStudents, undefined));

          setTimeout(() => {
            this.eventBusService.emit(new EmitEvent(Events.StateLoadParentStudentsGroups, undefined));
          }, 100);
          // this.prepareParentStudents();
        }
      }
    });


    //  const userLoggedInSubscription = this.eventBusService.on(Events.UserLoggedIn, (payload) => {
    //     const user = this.#userToSignal();
    //     if (user?.role === IUserRole.PARENT) {
    //       this.prepareParentStudents();
    //     }
    //   });
    const parentImpersonateStoppedSubscription = this.eventBusService.on(Events.ParentImpersonateStopped, (payload) => {
      const user = this.#userToSignal();
      if (user?.role === IUserRole.PARENT) {
        this.prepareParentStudents();
      }
    });

    const studentRegisteredSubscription = this.eventBusService.on(Events.StudentRegistered, (payload) => {
      const user = this.#userToSignal();
      if (user?.role === IUserRole.PARENT) {
        this.prepareParentStudents();
      }
    });

    this.subscriptions.push(userLoggedInSubscription, parentImpersonateStoppedSubscription, studentRegisteredSubscription);
  }

  private initTopbarItems() {
    // Initialize top menu items here (replace with your actual data)
    this.topMenuItems = [
      {
        id: 'languages',
        text: 'Languages',
        position: 'left',
        hidden: false,
        submenu: [
          { id: 'features', text: 'Features' },
          { id: 'solutions', text: 'Solutions' },
          { id: 'customers', text: 'Customers' }
        ]
      },
      {
        id: 'about',
        text: 'About',
        position: 'left',
        submenu: [
          { id: 'aboutCustomers', text: 'Customers' }
        ]
      },
      {
        id: 'packagesMenu',
        text: 'packages',
        position: 'left',
        submenu: [
        ]
      },
      {
        id: 'signin',
        text: 'Sign In/Up',
        icon: 'sign-in-icon',
        position: 'right',
        submenu: [
          {
            id: 'teacher',
            text: 'Teacher Hub',
            link: '/signin/teacher',
            icon: 'teacher-signin',
            prefixHtml: 'sign-in-menu-sprite teacher-signin'
          },
          {
            id: 'student',
            text: 'Student Hub',
            link: '/signin/student',
            icon: 'student-signin',
            prefixHtml: 'sign-in-menu-sprite student-signin'
          },
          {
            id: 'parent',
            text: 'Parent Hub',
            link: '/signin/parent',
            icon: 'parent-signin',
            prefixHtml: 'sign-in-menu-sprite parent-signin'
          },
          {
            id: 'create-account',
            text: 'Create an Account',
            link: '/request-free-trial',
            icon: 'create-parent-account',
            prefixHtml: 'sign-in-menu-sprite parcreate-parent-account'
          }
        ]
      },
      {
        id: 'profile',
        text: 'Profile',
        icon: 'profile-icon',
        link: '/dashboard/parent/overview',
        hidden: false,
        submenu: [
          {
            id: 'settings',
            text: 'Settings',
            link: '/user-settings/account-info',
            icon: 'settings-icon',
            hidden: false,
            callback: () => {
              this.menuVisibility['profile'] = false;
            }
          },
          {
            id: 'logout',
            text: 'Logout',
            icon: 'logout-icon',
            hidden: false,
            callback: () => {
              this.logout();
              this.menuVisibility['profile'] = false;
            }
          }
        ]
      },
      {
        id: 'notifications',
        text: 'Notifications',
        icon: 'notification-icon',
        hidden: true,
      }
      // Add more menu items as needed
    ];
  }

}
