@if (user()) {
<div class="w-full flex flex-column align-items-center justify-content-center">
    <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
    <a pRipple
        class="mb-2 flex align-items-center cursor-pointer gap-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">

        <span class="font-bold text-xl">Menu</span>
    </a>

    <ng-container *ngIf="userRole()?.role === IUserRole.STUDENT">

        <ul class=" mt-1 mb-2 list-none p-0">
            <p-scrollPanel [style]="{ width: '100%', height: '100%'  }" styleClass="custombar1 max-h-15rem">

                @for (item of generalService.studentMenuItems; track item.name) {
                <app-sidebar-main-menu-list-item [item]="item" [nameProperty]="'name'" [imageProperty]="'image'"
                    [link]="item.link" (useButtonSelected)="onSelectListItem($event)"></app-sidebar-main-menu-list-item>
                }

            </p-scrollPanel>
        </ul>
    </ng-container>


    @if(students$() && students$().data === null && !students$().loading) {
    <app-form-field-validation-message [severity]="Severity.Warning" styleClass="mb-1" messageClass="mb-1"
        [text]="'No Students found yet.'"></app-form-field-validation-message>
    }
    @if (user()) {
    <ng-container *ngIf="userRole()?.role === IUserRole.PARENT">
        <ul class="p-0 md:px-0 mx-0 mt-1 mb-2 list-none">
            <p-scrollPanel [style]="{ width: '100%', height: '100%'  }" styleClass="custombar1 max-h-17rem">

                @for (item of students$().data?.students; track item.id) {
                <app-sidebar-main-menu-list-item [item]="item" [baseProperty]="'studentBasicInfo'"
                    [nameProperty]="'firstName'" [defaultItemImage]="'assets/images/dummy/astronaut-01.png'"
                    [imageProperty]="'image'" [showEditButton]="false"
                    [link]="'/dashboard/parent/student/'+item.studentBasicInfo.id+'/overview'"
                    (useButtonSelected)="onSelectListItem($event)"></app-sidebar-main-menu-list-item>
                }

                @if(!studentGroups$() || studentGroups$().loading) {

                    <span class="inline-loader">
                        <span class="loader-box">

                        </span><span class="loader-box"></span>
                        <span class="loader-box"></span>
                    </span>
                    } @else {
                    @if(studentGroups$().data) {
                    @for (item of studentGroups$().data?.studentGroups; track item.id) {

                    <app-sidebar-main-menu-list-item [item]="item"
                        [textForNameProperty]="' ' + generalService.getILanguageLevelsEnumText(item.groupLevel)"
                        [nameProperty]="'groupLanguageName'"
                        [isGroup]="true"
                        [defaultItemImage]="'assets/images/graphic/universe-planet-right-center-eyes.webp'"
                        [imageProperty]="'image'" [showEditButton]="true"></app-sidebar-main-menu-list-item>

                    <ng-container *ngFor="let groupItem of item.studentsBasicInfo">

                    </ng-container>
                    }
                    }
                }
            </p-scrollPanel>
        </ul>
    </ng-container>
    }

    <ng-container *ngIf="user()?.role === IUserRole.PARENT && students$().data !== null && students$().data.students && students$().data.students.length > 0">
        <div class="mt-auto mb-3"><p-button (click)="openNewGroupDialog()" label="Create Group"
                [outlined]="true" [rounded]="true" severity="secondary" [iconPos]="'left'"
                [icon]="'pi pi-plus'" size="small" styleClass="color-c2"></p-button>
        </div>
    </ng-container>

    <div class="flex flex-column w-9 sm:w-20rem align-items-center gap-2">
        <p-button label="My Profile" class="w-full" styleClass="bg-indigo-400 text-white w-full" (click)="goToHome()"
            size="large" [outlined]="true" [rounded]="true"></p-button>
        <p-button [rounded]="true" class="w-full azure-bg border-round-3xl" size="large" (click)="goToUserSettings()"
            styleClass=" border-round-3xl" styleClass=" w-full" label="Settings"></p-button>
        <p-button label="Logout" class="w-full" styleClass="bg-indigo-100 text-white w-full" (click)="goToLogout()"
            size="large" [outlined]="true" [rounded]="true"></p-button>
    </div>
</div>
}@else {

<div class="mx-auto row grid align-items-center justify-content-center text-center w-full">
    <div class="mx-auto mt-5 sm:w-24rem w-full text-center">
        <a pRipple
            class="mb-2 flex align-items-center cursor-pointer gap-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">

            <span class="font-bold text-xl">Menu</span>
        </a>
        @for (item of topButtonMenuItems; track item.text) {
        <p-button class="youtube p-0"
            styleClass="bg-indigo-400 text-white text-center w-full flex justify-content-center mb-3"
            aria-label="Youtube" [severity]="Severity.Secondary" [outlined]="true" [rounded]="true" (click)="navigateTo(item)">
            <i class="pi pi-user px-2"></i>
            <span class="px-3">{{item.text}}</span>
        </p-button>
        }

    </div>
</div>

}