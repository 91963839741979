import { ApplicationConfig, CSP_NONCE, ErrorHandler, importProvidersFrom } from '@angular/core';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ApiResponseInterceptor } from './core/interceptors/apiResponse.interceptor';
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy, PathLocationStrategy, PlatformLocation } from '@angular/common';
import { CustomErrorHandler } from './core/helpers/custom-error-handler';
import { MessageService } from 'primeng/api';
import { authInterceptor } from './core/interceptors/auth.interceptor';
import { httpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { HttpRequestInterceptor } from './core/interceptors/http.interceptor';
import { GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { loadingBarInterceptor } from './core/interceptors/loading-bar.interceptor';

const globalThis = window; // Adjust if using a different global object

function generateRandomNonce() {
  const array = new Uint32Array(10);
  crypto.getRandomValues(array);
  return array.join('');
}

const myRandomNonceValue = generateRandomNonce();

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};
const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes, inMemoryScrollingFeature),
    MessageService,
  importProvidersFrom(BrowserModule),
  importProvidersFrom(BrowserAnimationsModule),
  provideHttpClient(withInterceptorsFromDi(), withInterceptors([httpErrorInterceptor, loadingBarInterceptor])),
  // { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
  // {
  //   provide: ErrorHandler,
  //   useClass: CustomErrorHandler
  // },
  {
    provide: CSP_NONCE,
    useValue: myRandomNonceValue
  },
  { provide: LocationStrategy, useClass: PathLocationStrategy },
  {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            '321797914639-kognesknm35107f7jsrf213m3ikg2ftv.apps.googleusercontent.com',
          ),
        },
      ],
      onError: (err) => {
        console.error(err);
      }
    } as SocialAuthServiceConfig,
  }
  ]

};
