import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, type OnInit } from '@angular/core';
import { Observable, Subscription, filter, fromEvent, mapTo, merge, of, pairwise, startWith } from 'rxjs';
import { untilDestroyed } from 'src/app/core/helpers/until-destroyed';
import { ApiService } from 'src/app/core/services/api.service';
import { DataApiStateService } from '../../../core/services/data-api-state.service';
import { EventBusService, Events } from 'src/app/core/services/event-bus.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { ImpersonateStudentRequest } from '@GeneratedTsFiles/Identity/ImpersonateStudentRequest';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastMessages, getToastMessage } from '../../models/toast-messages';
import { IGetStudentGroupResponse, IParents, StudentGroupRoutes } from '@GeneratedTsFiles/index';

@Component({
    selector: 'app-state-api-calls',
    standalone: true,
    imports: [
        CommonModule,
    ],
    templateUrl: './state-api-calls.component.html',
    styleUrl: './state-api-calls.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateApiCallsComponent implements OnInit {
    apiService = inject(ApiService);
    generalService = inject(GeneralService);
    dataStateService = inject(DataApiStateService);
    eventBusService = inject(EventBusService);
    authService = inject(AuthService);
    toastService = inject(ToastService);
    private subscriptions: Subscription[] = [];
    private untilDestroyed = untilDestroyed();
    previousOnline = navigator.onLine;

    #userToSignal = this.authService.userDecodedJWTData$;
    online$: Subscription = {} as Subscription;
    user = computed(() => {
        console.log(this.#userToSignal());
        return this.#userToSignal();
    });

    ngOnInit(): void {
        this.initEvents();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private initEvents() {

        this.checkNavigatorStatus();

        const loadTeachingLanguageSubscription = this.eventBusService.on(Events.StateLoadTeachingLanguages, (payload) => {
            this.loadLanguages();
            // this.dialogVisible.set(false);
        });
        const stateLoadParentStudentsSubscription = this.eventBusService.on(Events.StateLoadParentStudents, (payload) => {
            this.loadParentStudents();
            // this.dialogVisible.set(false);
        });

        const stateLoadParentStudentsGroupSubscription = this.eventBusService.on(Events.StateLoadParentStudentsGroups, (payload) => {
            this.loadParentStudentsGroups();
            // this.dialogVisible.set(false);
        });

        const stateLoadStartImpersonateSubscription = this.eventBusService.on(Events.StateLoadStartImpersonate,
            (payload) => {
                console.log(payload);
                this.loadStartImpersonate(payload);
                // this.dialogVisible.set(false);
            });

        const studentGroupAddedSubscription = this.eventBusService.on(Events.StudentGroupAdded, (payload) => {
            this.loadParentStudentsGroups();
            // this.dialogVisible.set(false);
        });

        this.subscriptions.push(
            loadTeachingLanguageSubscription,
            studentGroupAddedSubscription,
            stateLoadParentStudentsSubscription,
            stateLoadParentStudentsGroupSubscription,
            stateLoadStartImpersonateSubscription
        );
    }

    loadLanguages() {
        const apiCall: Observable<any> = this.apiService.getTeachingLanguages();
        this.dataStateService.handleApiCall(apiCall, this.dataStateService.teachingLanguages.setState)
            .pipe(this.untilDestroyed())
            .subscribe({
                next: () => { }, // The state is already handled in handleApiCall
                error: (err) => {
                    console.log('error', err);
                } // The state is already handled in handleApiCall
            });
    }

    loadParentStudents() {
        const apiCall: Observable<any> = this.apiService.getApiData<any>(
            { url: IParents.getStudents, method: 'GET' },
            {
                ParentId: this.user()!.id
            })
        this.dataStateService.handleApiCall(apiCall, this.dataStateService.parentStudents.setState)
            .pipe(this.untilDestroyed())
            .subscribe({
                next: () => { }, // The state is already handled in handleApiCall
                error: (err) => {
                    console.log('error', err);
                } // The state is already handled in handleApiCall
            });
    }

    loadParentStudentsGroups() {
        const apiCall: Observable<any> = this.apiService.getApiData<IGetStudentGroupResponse>(
            { url: StudentGroupRoutes.getAllForParent, method: 'GET' },
            {
                "parentId": this.user()?.id,
            });
        this.dataStateService.handleApiCall(apiCall, this.dataStateService.parentStudentsGroups.setState)
            .pipe(this.untilDestroyed())
            .subscribe({
                next: () => { }, // The state is already handled in handleApiCall
                error: (err) => {
                    console.log('error', err);
                } // The state is already handled in handleApiCall
            });
    }

    loadStartImpersonate(payload: ImpersonateStudentRequest) {
        const apiCall: Observable<ImpersonateStudentRequest> = this.apiService.impersonateStudent({
            impersonateStudentId: payload.impersonateStudentId,
            parentRefreshToken: this.authService.getRefreshToken()
        });
        this.dataStateService.handleApiCall(apiCall, this.dataStateService.startImpersonateStudent.setState)
            .pipe(this.untilDestroyed())
            .subscribe({
                next: (response) => {

                    console.log(response);

                    this.authService.handleUserDataAndDecodeJWT(response);
                    const user = this.authService.getUserDecodedData()!;
                    this.generalService.navigateToYoungsterDashboard();


                    // this.eventBusService.emit(new EmitEvent(Events.StudentGroupNewMemberAdded, undefined));
                    this.toastService.show(getToastMessage(ToastMessages.ParentImpersonateStarted.success, { data: user.firstName }));
                },
                error: (err) => {
                    console.log('error', err);
                } // The state is already handled in handleApiCall
            });
    }

    private checkNavigatorStatus() {
        this.online$ = merge(
            of(navigator.onLine), // Initial online status
            fromEvent(window, 'online').pipe(mapTo(true)), // Event when online
            fromEvent(window, 'offline').pipe(mapTo(false)) // Event when offline
        ).pipe(
            this.untilDestroyed(),
            startWith(this.previousOnline), // Start with current online status
            pairwise(), // Pair current and previous values
        ).subscribe(([prev, curr]) => {
            console.log('Online status changed:', curr);
            if (curr && !prev) {
                this.toastService.show(ToastMessages.InternetConnection.success);
            } else if (!curr && prev) {
                this.toastService.show(ToastMessages.InternetConnection.error);
            }
            this.previousOnline = curr;
        });
    }
}
