import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, ViewChild, type OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { GeneralService } from 'src/app/core/services/general.service';
import { BuyPackageSidebarComponent } from 'src/app/features/buy-package/buy-package-sidebar/buy-package-sidebar.component';

@Component({
    selector: 'app-cart-sidebar',
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
        SidebarModule,
        BuyPackageSidebarComponent
    ],
    templateUrl: './cart-sidebar.component.html',
    styleUrl: './cart-sidebar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartSidebarComponent implements OnInit {
    generalService = inject(GeneralService);
    inSidebar = input(false);
    sidebarVisible = true;
    @ViewChild('sidebarRef') sidebarRef!: Sidebar;
    ngOnInit(): void { }


    onShowSidebar(showSidebar: boolean) {
        this.sidebarVisible = showSidebar;
    }

    closeCallback(e: Event): void {
        this.sidebarRef.close(e);
    }
}
