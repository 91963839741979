<div #profileDiv class="flex justify-content-between align-items-center px-2 md:px-5 surface-0 border-bottom-1 surface-border 
  fixed w-full main-header-wrap" style="height: 70px;">
    <div class="flex">

        <div class="flex align-items-center lg:px-0 flex-shrink-0 mr-2">
            <a  (click)="authService.goToDashboardPerRole()" class="flex align-items-center cursor-pointer">
                <img src="assets/images/lingogalaxy-logo.svg" alt="logo" height="50">
            </a>
            <h2 class="text-purple-700 text-lg"></h2>
        </div>
        @if (authService.userDecodedJWTData$()) {
        @if (userRole()?.role === IUserRole.PARENT) {


        <ul class="list-none p-0 m-0 hidden xl:flex align-items-center select-none flex-row surface-section">
            <ng-container *ngFor="let menuItem of topMenuItems">
                <li *ngIf="menuItem.id === 'packagesMenu'" class="relative hidden md:block align-items-center"
                    (mouseleave)="onMouseLeave(menuItem.id)">
                    <ng-container>
                        <a pRipple class="inline-flex  align-items-center
    transition-duration-150 
    md:ml-4 mr-2 px-3 cursor-pointer
                buy-package-grad-btn font-medium transition-colors transition-duration-150
    " (mouseenter)="onMouseEnter(menuItem.id)">
                            <div class="flex align-items-center justify-content-center gap-2">
                                <img src="assets/images/rocket.png" alt="logo" height="24">
                                <span class="font-semibold">Buy Package</span>
                            </div>
                            <i class="pi pi-chevron-down ml-auto lg:ml-3"></i>
                        </a>

                        <ul #buyPackageMenu *ngIf="menuVisibility[menuItem.id]" class="list-none p-0 m-0 buy-package-grad-btn border-round shadow-0 lg:shadow-2 lg:absolute 
                    surface-overlay  origin-top w-full lg:w-12rem" style="left: 24px;">
                            <li class="px-3">
                                <a pRipple (click)="goToBuyPackage()"
                                    class="flex gap-2 py-1 align-items-center justify-content-start cursor-pointer transition-colors transition-duration-150">
                                    <img src="assets/images/icons/meteorite.png" alt="logo" height="18">
                                    <span>New Package</span>
                                </a>
                            </li>
                            <li class="px-3">
                                <a pRipple (click)="goToBuyPackageExtension()"
                                    class="flex gap-2 py-1 align-items-center justify-content-start cursor-pointer transition-colors transition-duration-150">
                                    <img src="assets/images/icons/shooting-stars.png" alt="logo" height="18">
                                    <span>Buy an extension</span>
                                </a>
                            </li>
                        </ul>

                    </ng-container>
                </li>
            </ng-container>
        </ul>

        }
        }


    </div>

    <div class="flex align-items-center">
        <ul class="list-none p-0 m-0 hidden xl:flex align-items-center select-none flex-row surface-section">


            @if (!authService.isAuth()) {
            <li *ngFor="let menuItem of topMenuItems" class="relative text-900"
                (mouseleave)="onMouseLeave(menuItem.id)">
                <ng-container *ngIf="menuItem.position === 'right'">
                    <a pRipple class="signin-links-start flex px-0 lg:px-3 py-2 align-items-center hover:text-blue-600 font-medium transition-colors 
                        transition-duration-150 menu-item" (mouseenter)="onMouseEnter(menuItem.id)"
                        (click)="toggleSubmenu(menuItem.id)">
                        <span>{{ menuItem.text }}</span>
                        <i class="pi pi-chevron-down text-sm ml-auto lg:ml-2"></i>
                    </a>
                    <ul *ngIf="menuVisibility[menuItem.id]"
                        class="signin-links-list list-none p-0 m-0 border-round shadow-0 lg:shadow-2 lg:absolute surface-overlay origin-top w-full lg:w-13rem">
                        <li *ngFor="let submenuItem of menuItem.submenu" [ngClass]="{ 'active': submenuItem.active }">
                            <ng-container *ngIf="submenuItem.link; else callbackContent">
                                <a pRipple (click)="navigateTo(submenuItem)"
                                    class="flex p-2 align-items-center hover:surface-50 transition-colors transition-duration-150 cursor-pointer">
                                    <span class="flex gap-1 justify-content-center">
                                        <span *ngIf="submenuItem.prefixHtml"
                                            class="{{ submenuItem.prefixHtml }}"></span>
                                        {{ submenuItem.text }}
                                    </span>
                                </a>
                            </ng-container>
                            <ng-template #callbackContent>
                                <button pRipple *ngIf="submenuItem.callback" (click)="submenuItem.callback($event)"
                                    class="flex p-2 align-items-center hover:surface-50 transition-colors transition-duration-150 cursor-pointer">
                                    <span class="flex gap-1 justify-content-center">
                                        <span class="sign-in-menu-sprite {{ submenuItem.icon }}"></span>
                                        <span [innerHTML]="submenuItem.prefixHtml"></span>
                                        {{ submenuItem.text }}
                                    </span>
                                </button>
                            </ng-template>
                        </li>
                    </ul>
                </ng-container>
            </li>
            <!-- <li class="relative text-900" (mouseleave)="onMouseLeave('signin')">
                <a pRipple class="signin-links-start flex px-0 lg:px-3 py-2 align-items-center hover:text-blue-600 font-medium transition-colors 
                    transition-duration-150 menu-item" (mouseenter)="onMouseEnter('signin')">
                    <span>Sign in/up

                    </span>

                    <i class="pi pi-chevron-down text-sm ml-auto lg:ml-2"></i>
                </a>


                <ul *ngIf="menuVisibility.signin" class="signin-links-list list-none p-0 m-0 border-round shadow-0 lg:shadow-2 lg:absolute surface-overlay  
    origin-top w-full lg:w-13rem">
                    <li>
                        <a pRipple (click)="navigateTo('/signin/teacher')"
                            class="flex p-2 align-items-center hover:surface-50 transition-colors transition-duration-150 cursor-pointer">
                            <span class="flex gap-1 justify-content-center"><span class="sign-in-menu-sprite teacher-signin"></span>Teacher Hub</span>
                        </a>
                    </li>
                    <li>
                        <a pRipple (click)="navigateTo('/signin/student')"
                            class="flex p-2 align-items-center hover:surface-50 transition-colors transition-duration-150 cursor-pointer">
                            <span class="flex gap-1 justify-content-center"><span class="sign-in-menu-sprite student-signin"></span>Student Hub</span>
                        </a>
                    </li>
                    <li>
                        <a pRipple (click)="navigateTo('/signin/parent')"
                            class="flex p-2 align-items-center hover:surface-50 transition-colors transition-duration-150 cursor-pointer">
                            <span class="flex gap-1 justify-content-center"><span class="sign-in-menu-sprite parent-signin"></span>Parent Hub</span>
                        </a>
                    </li>
                    <li>
                        <a pRipple (click)="navigateTo('/request-free-trial')"
                            class="flex p-2 align-items-center hover:surface-50 transition-colors transition-duration-150 cursor-pointer">
                            <span class="flex gap-1 justify-content-center"><span class="sign-in-menu-sprite create-parent-account"></span>Create an Account</span>
                        </a>
                    </li>
                </ul>

            </li> -->
            }

            @if (authService.isAuth()) {

            @if (userRole()?.role === IUserRole.PARENT) {
            <li class="hidden md:block">
                <a pRipple (click)="goToCartDetails()"
                    class="text-cyan-600 font-medium inline-flex align-items-center cursor-pointer px-2 sm:px-3 hover:text-primary">
                    <i class="pi pi-shopping-cart lg:mr-3 text-sm sm:text-2xl" pBadge styleClass="top-badge"
                        value="2"></i>
                </a>
            </li>
            }
            <li class="hidden md:block" *ngIf="userRole()?.role === IUserRole.PARENT">
                <p-button label="" (click)="goToFreeTrialForm()" styleClass="trial-btn fly mr-2" size="small"
                    [rounded]="true" iconPos="right">
                    <div class="flex align-items-center justify-content-center gap-2">
                        <span class="font-semibold">Free Trial </span>
                        <img src="assets/images/rocket.png" alt="logo" height="24">
                    </div>
                </p-button>
            </li>
            <li class="hidden md:block">
                <p-button label="My Profile" styleClass="primary-purple-color" (click)="goToHome()" size="small"
                    [outlined]="true" [rounded]="true"></p-button>

            </li>

            <li class="hidden md:block">
                @if (user()) {
                    
                @if (user()!.isImpersonated === true) {

                <a pRipple class="inline-flex px-0 py-3 align-items-center hover:text-blue-600 font-medium transition-colors 
        transition-duration-150 menu-item justify-content-end" (click)="stopImpersonation()">
                    <div class="avatar-circle smaller">
                        <img src="assets/images/dummy-image.png" class="border-round">
                    </div>
                    <div class="hidden lg:block lg:ml-2">
                        <!-- <div class="text-900 font-medium">Hi {{ user()?.originalUserFirstName }}</div> -->
                        <span class="text-600 font-medium text-sm w-15rem">Parent profile</span>
                    </div>

                </a>

                <!-- <p-button label="Stop" styleClass="text-white" (click)="stopImpersonation()"></p-button> -->
                }
            }
            </li>

            <li class="hidden md:block" (mouseleave)="onMouseLeave('notifications')">
                <a pRipple (click)="goToNotifications()"
                    class="p-ripple p-element flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors"><i
                        severity="danger" class="p-element pi pi-bell text-base lg:text-2xl p-overlay-badge"><span
                            id="pr_id_1_badge" class="p-badge p-component p-badge-danger p-badge-dot"></span></i>
                </a>
                <ul *ngIf="menuVisibility['notifications']"
                    class="list-none p-0 m-0 border-round shadow-0 lg:shadow-2 lg:absolute surface-overlay origin-top w-full lg:w-15rem">
                    <!-- Use a nested *ngFor for submenu items -->
                    <li>
                        <a pRipple
                            class="flex p-3 align-items-center hover:surface-50 transition-colors transition-duration-150">
                            <div></div><span>sadsad</span>
                        </a>
                    </li>
                </ul>

            </li>

            <!-- dynamic-menu.component.html -->
            <ng-container *ngFor="let menuItem of topMenuItems">
                <li *ngIf="menuItem.id === 'profile'" class="relative hidden md:block"
                    (mouseleave)="onMouseLeave(menuItem.id)">
                    <ng-container>
                        <a pRipple class="inline-flex px-0 py-3 align-items-center hover:text-blue-600 font-medium transition-colors 
    transition-duration-150 menu-item justify-content-end" (mouseenter)="onMouseEnter(menuItem.id)">
                            <div class="avatar-circle">
                                <img src="assets/images/dummy-image.png" class="border-round">
                            </div>
                            <div class="hidden lg:block lg:ml-2">
                                <div class="text-900 font-medium">Hi {{ user()?.firstName }}</div>
                                <!-- <span class="text-600 font-medium text-sm w-15rem">{{ user()?.firstName }}</span> -->
                            </div>
                            <i class="pi pi-chevron-down text-sm ml-auto lg:ml-2"></i>
                        </a>

                        <ul *ngIf="menuVisibility[menuItem.id]" class="list-none p-0 m-0 border-round shadow-0 lg:shadow-2 lg:absolute surface-overlay   
origin-top w-full lg:w-12rem" style="left: -20px;">
                            <li *ngFor="let subMenuItem of menuItem.submenu">

                                <ng-container *ngIf="subMenuItem.link; else callbackContent">

                                    <a pRipple (click)="navigateTo(subMenuItem)"
                                        class="flex no-underline p-3 align-items-center cursor-pointer text-700 hover:surface-50 transition-colors transition-duration-150">
                                        <span>{{ subMenuItem.text }}</span>
                                    </a>
                                </ng-container>
                                <ng-template #callbackContent>


                                    <a pRipple *ngIf="subMenuItem.callback" (click)="subMenuItem.callback($event)"
                                        class="flex no-underline p-3 align-items-center text-700 hover:surface-50 transition-colors transition-duration-150">
                                        <span>{{ subMenuItem.text }}</span>
                                    </a>
                                </ng-template>

                            </li>
                        </ul>
                    </ng-container>
                </li>
            </ng-container>
            }

        </ul>

        <a (click)="toggleSidebar()" pRipple pstyleclass="#sidebar" enterFromClass="hidden"
            enteractiveclass="fadeinleft" leavetoclass="hidden" leaveactiveclass="fadeoutleft"
            class="p-ripple p-element cursor-pointer inline-flex align-items-center justify-content-center xl:hidden text-700 mr-2">
            <i class="pi pi-bars text-4xl burger-bars"></i>
        </a>
    </div>
</div>

<p-sidebar [visible]="sidebarVisible()" (visibleChange)="onSidebarVisibleChange($event)" [fullScreen]="true"
    [blockScroll]="true" appendTo="body" role="region">
    <h3>Sidebar</h3>
    <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua.
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
    </p>

    <ng-template pTemplate="headless">


        <div class="flex flex-column h-full">
            <div class="flex align-items-center justify-content-between px-3 pt-2 flex-shrink-0">
                <span class="inline-flex align-items-center gap-2">
                    <span class="font-semibold text-2xl text-primary">
                        <img src="assets/images/logo-purple.png" alt="logo" height="30"></span>
                </span>
                <span>
                    <p-button type="button" [rounded]="true" [text]="true" (click)="closeCallback($event)"
                        icon="pi pi-times" styleClass="h-2rem w-2rem"></p-button>
                </span>
            </div>
            <div class="overflow-y-auto">

             
                <app-sidebar-topbar-menu [topMenuItems]="topMenuItems" (onSidebarVisibleChange)="onSidebarVisibleChange($event)"></app-sidebar-topbar-menu>
                <!-- <ul class=" list-none p-3 m-0">
                    <li>

                        <ul class="list-none p-0 m-0 overflow-hidden">


                            <li class="relative" *ngFor="let menuItem of topMenuItems"
                                (mouseleave)="onMouseLeave(menuItem.id)">
                                <ng-container *ngIf="menuItem.hidden ? user() ? true : false : true">

                                    <div pRipple pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown"
                                        leaveToClass="hidden" leaveActiveClass="slideup" enterActiveClass="slidedown"
                                        class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple">
                                        <span class="font-medium">{{ menuItem.text }}</span>
                                        <i class="pi pi-chevron-down"></i>
                                    </div>
                                    <ul
                                        class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden hidden transition-all transition-duration-400 transition-ease-in-out">
                                      
                                        <li *ngFor="let subItem of menuItem.submenu">
                                            <ng-container *ngIf="subItem.link; else callbackContent">

                                                <a pRipple (click)="navigateTo(subItem)"
                                                    class="flex gap-1 cursor-pointer p-3 no-underline text-600 align-items-center hover:surface-50 transition-colors transition-duration-150">
                                                    <div [class]="subItem.prefixHtml"></div><span>{{ subItem.text
                                                        }}</span>
                                                </a>
                                            </ng-container>

                                            <ng-template #callbackContent>
                                                <a pRipple *ngIf="subItem.callback" (click)="subItem.callback($event)"
                                                    class="flex gap-1 no-underline p-3 align-items-center text-700 hover:surface-50 transition-colors transition-duration-150">
                                                    <div [class]="subItem.prefixHtml"></div><span>{{ subItem.text
                                                        }}</span>
                                                </a>
                                            </ng-template>
                                        </li>
                                    </ul>
                                </ng-container>
                            </li>

                        </ul>
                    </li>
                </ul> -->
            </div>

            @if (user()) {
            <div class="mt-auto">
                <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
                <!-- <a pRipple
                    class="m-3 flex align-items-center cursor-pointer p-3 gap-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                    <p-avatar [image]="user()?.profilePhoto" shape="circle" />
                    <span class="font-bold">{{ user()?.firstName }}</span>
                </a> -->


                <div class="flex flex-column w-full align-items-center gap-0">
                    <button pButton
                        class="w-full dark-purple-bg-btn font-semibold p-3 text-center flex align-items-center justify-content-center m-auto border-noround">
                        <span class="">Need Help</span>
                        <img src="/assets/images/planet-purple.webp" class="absolute right-0 w-3rem pr-2" />
                    </button>
                    <button pButton (click)="goToBuyPackage()"
                        class="w-full azure-bg-btn font-semibold p-3 text-center flex align-items-center justify-content-center m-auto border-noround">
                        <span class="">Buy Package</span>
                        <img src="/assets/images/full-chevron-white-right.png" class="absolute right-0 w-2rem pr-2" />
                    </button>
                </div>

            </div>
            }
        </div>

    </ng-template>



</p-sidebar>