import { inject } from '@angular/core';
import type { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { GeneralService } from '../services/general.service';

export const signedInGuard: CanActivateFn = (route, state) => {


  const authService = inject(AuthService);
  const generalService = inject(GeneralService);
  const isAuth = authService.isAuth();

  if (isAuth && state.url === '/request-free-trial') {
    generalService.navigateToParentDashboard();
    return true;
  }
  
  return true;
};
