import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, inject, input, Output, type OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { FormFieldValidationMessageComponent } from '../../prime/form-field-validation-message/form-field-validation-message.component';
import { SidebarMainMenuListItemComponent } from '../../sidebar/sidebar-main-menu-list-item/sidebar-main-menu-list-item.component';
import { DataApiStateService } from '../../../../core/services/data-api-state.service';
import { GeneralService } from '../../../../core/services/general.service';
import { AuthService } from '../../../../core/services/auth.service';
import { IUserRole, TopMenuItem } from 'src/app/shared/models/general.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-sidebar-topbar-menu',
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
        ScrollPanelModule,
        FormFieldValidationMessageComponent,
        SidebarMainMenuListItemComponent,

    ],
    templateUrl: './sidebar-topbar-menu.component.html',
    styleUrl: './sidebar-topbar-menu.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarTopbarMenuComponent implements OnInit {

    generalService = inject(GeneralService);
    dataApiStateService = inject(DataApiStateService);
    authService = inject(AuthService);
    router = inject(Router);
    IUserRole = IUserRole;

    topMenuItems = input<TopMenuItem[]>([]);

    #userToSignal = this.authService.userDecodedJWTData$;
    user = computed(() => {
        return this.#userToSignal();
    });
    userRole = computed(() => {
        return (this.authService.userDecodedJWTData$());
    });
    students$ = computed(() => {
        return this.dataApiStateService.parentStudents.state() || [];
    });

    @Output() onSidebarVisibleChange = new EventEmitter<boolean>();


    topButtonMenuItems = [{
        id: 'teacher',
        text: 'Teacher Hub',
        link: '/signin/teacher',
        icon: 'teacher-signin',
        prefixHtml: 'sign-in-menu-sprite teacher-signin'
    },
    {
        id: 'student',
        text: 'Student Hub',
        link: '/signin/student',
        icon: 'student-signin',
        prefixHtml: 'sign-in-menu-sprite student-signin'
    },
    {
        id: 'parent',
        text: 'Parent Hub',
        link: '/signin/parent',
        icon: 'parent-signin',
        prefixHtml: 'sign-in-menu-sprite parent-signin'
    },
    {
        id: 'create-account',
        text: 'Create an Account',
        link: '/request-free-trial',
        icon: 'create-parent-account',
        prefixHtml: 'sign-in-menu-sprite parcreate-parent-account'
    }
    ];

    ngOnInit(): void { }

    onSelectListItem($event: { item: any, link: string }) {
        console.log($event);
        if ($event && $event.link) {
            this.router.navigate([$event.link]);
        }
        this.onSidebarVisibleChange.emit(false);
    }

    goToHome() {
        this.authService.goToDashboard();
        this.onSidebarVisibleChange.emit(false);
    }

    goToUserSettings() {
        this.authService.goToUserSettings();
        this.onSidebarVisibleChange.emit(false);
    }
    goToLogout() {
        this.authService.logout();
        this.onSidebarVisibleChange.emit(false);
    }

    navigateTo(menuItem: any) {
        const url = menuItem['link'];
        if (!url) {
            return;
        }
        this.router.navigate([url]);
        this.onSidebarVisibleChange.emit(false);
    }

}
