import { Injectable, inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpInterceptorFn, HttpEventType } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';
import { IApiResponse, IApiResponseBase, IHttpStatusCode, IRefreshTokenResponse, IdentityRoutes, LocationDataRoutes } from '@GeneratedTsFiles/index';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

let isRefreshing = false;
let refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {

  const toastService = inject(ToastService);
  const userService = inject(UserService);
  const authService = inject(AuthService);
  const router = inject(Router);
  const token = inject(AuthService).getAuthData().token;
  const refreshToken = inject(AuthService).getAuthData().refreshToken;
  let interceptedReq: HttpRequest<any>; // Store the intercepted request


  return next(attachAuthorizationHeader(req)).pipe(
    tap((event: HttpEvent<any>) => {
      if (event instanceof HttpRequest) {
        interceptedReq = event; // Save the intercepted request
      }
    }),
    map((event: HttpEvent<any>) => {
      if (event.type === HttpEventType.Response) {
        const body = event.body;
        if (body && body.messages) {
          const formErrors = body.messages;
          const errorMessages = Object.values(formErrors).flat();
          let concatenatedErrorMessage = errorMessages.join('<br>');
          setTimeout(() => {
            // toastService.show({
            //   summary: 'Message',
            //   detail: concatenatedErrorMessage,
            //   severity: 'info'
            // });
          }, 100);
        }
      }
      return event;

    }),
    catchError((error: HttpErrorResponse) => {
      console.log(error);
      if ((error.error || error.status === IHttpStatusCode.Unauthorized)) {

        if (error.url && error.url.includes(IdentityRoutes.postRefreshToken)) {
          // isRefreshing = false;
          authService.logout();
          // alert('Session expired. Please login again.');
        }
        
        interceptedReq = req; // Store the intercepted request here
        let concatenatedErrorMessage = '';
        const errorMessage = 'An unknown error occurred.' || 'An unknown error occurred.';

        if (error.error && error.error.formValidationErrors) {
          const formErrors = error.error.formValidationErrors;
          if (formErrors) {
            const errorMessages = Object.values(formErrors).flat();

            concatenatedErrorMessage += errorMessages.join('<br>');
          }
        }

        switch (error.status) {
          case IHttpStatusCode.Unauthorized:
            if (!isRefreshing) {
              isRefreshing = true;
              refreshTokenSubject.next(null);

              return userService.refreshToken(
                {
                  token: token,
                  refreshToken: refreshToken
                }
              )
                .pipe(
                  switchMap((tokens: IRefreshTokenResponse | any) => {
                    isRefreshing = false;
                    refreshTokenSubject.next(tokens.token);
                    // Refresh successful, update request with new access token
                    authService.setRefreshToken(tokens.refreshToken);
                    authService.setToken(tokens.token);
                    console.log('Refreshed token: ', tokens.token);
                    console.log('interceptedReq: ', interceptedReq);
                    const updatedReq = interceptedReq.clone({ setHeaders: { Authorization: `Bearer ${tokens.token}` } });
                    // Retry the request with the new token
                    return next((updatedReq));
                  }),
                  catchError((refreshError: IApiResponseBase) => {
                    isRefreshing = false;
                    refreshTokenSubject.next(null);
                    console.log(refreshError);
                    // Refresh token failed, handle error (e.g., logout) 
                    if (refreshError.statusCode === IHttpStatusCode.Unauthorized || refreshError.statusCode === IHttpStatusCode.Forbidden || refreshError.statusCode === IHttpStatusCode.BadRequest || refreshError.statusCode === IHttpStatusCode.NotFound || refreshError.statusCode === IHttpStatusCode.InternalServerError) {
                      //TODO: add Logout logic
                      authService.logout();
                      console.error('Refresh token failed:', refreshError);
                      return throwError(() => error);
                    } else {
                      return throwError(() => error);
                    }
                  })
                );
            } else {
              return refreshTokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap((newToken) => {
                  const updatedReq = interceptedReq.clone({ setHeaders: { Authorization: `Bearer ${newToken}` } });
                  return next(updatedReq);
                })
              );
            }
          case IHttpStatusCode.Forbidden: // Forbidden
            console.error('Forbidden');
            break;
          case IHttpStatusCode.BadRequest:
          case IHttpStatusCode.NotFound:
            console.error('Bad request');
            // Display a toast message using injected ToastService (assuming a provider)
            setTimeout(() => {

              // processErrorMessages(error, toastService);
            }, 100);
            break;
          default:
            //  toastService.show({
            //   summary: 'Message',
            //   detail: error.statusText,
            //   severity: 'info'
            // });
            console.error('Error occurred:', error);
        }

        return throwError(() => error);
      } else {
        console.error(error);
        if (error.status === IHttpStatusCode.Forbidden) {
          toastService.show({
            summary: 'Message',
            detail: 'Forbidden action',
            severity: 'warn'
          });
        }
        return throwError(() => error);
      }
    })
  );
};

function processErrorMessages(error: any, toastService: ToastService) {
  if (error && error.error && error.error.messages) {
    const formErrors = error.error.messages;
    const errorMessages = Object.values(formErrors).flat();
    let concatenatedErrorMessage = errorMessages.join('<br>');
    concatenatedErrorMessage = concatenatedErrorMessage.replace(/<br>/g, '\n');

    toastService.show({
      summary: 'Message',
      detail: concatenatedErrorMessage,
      severity: 'error'
    });
  }
}

function attachAuthorizationHeader(request: HttpRequest<any>): HttpRequest<any> {
  const requiresAuth = !isRouteUnprotected(request.url); // Check if route requires auth
  console.log('Requires Auth:', requiresAuth);
  const authService = inject(AuthService);
  const token = requiresAuth ? authService.getAuthData()?.token || null : null;
  if (token) {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
  } else {
    // Handle case where user is not authenticated (e.g., no token)
    return request;
  }
}
// Implement logic to check if the URL requires authorization based on your routing configuration
function isRouteUnprotected(url: string): boolean {
  const locationDataRouteValues = Object.values(LocationDataRoutes).map(route => route as string); // Cast to string
  const protectedRoutes = [
    ...locationDataRouteValues,
    IdentityRoutes.postRegisterParent,
    IdentityRoutes.postLogin,
  ];

  return protectedRoutes.some(route => url.includes(route));
}


function handle401Error(request: HttpRequest<any>, next: HttpHandler) {
  isRefreshing = false;
  if (!isRefreshing) {
    isRefreshing = true;

    const token = inject(AuthService).getAuthData().token;
    const refreshToken = inject(AuthService).getAuthData().refreshToken;
    return inject(UserService).refreshToken({
      token: token,
      refreshToken: refreshToken
    }).pipe(take(1),
      switchMap((result: IRefreshTokenResponse | any) => {
        console.log('Refreshed token', result);
        isRefreshing = false;

        return next.handle(request);
      }),
      catchError((error) => {
        console.log('Refreshed token', error);
        isRefreshing = false;

        if (error.status == '403') {

          // this.eventBusService.emit(new EventData('logout', null));
        }

        return throwError(() => error);
      })
    );
  }

  return next.handle(request);
}

